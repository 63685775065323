(function($) {
  
  /*
  Global variables
  */
  
  $(document).foundation();
  
  var card = $('.m-pan__inner');
  var canMoveCard = true;
  var infoNav = $('#menu-item-46 a');
  var animatingTransition = false;
  var fading, reveal, descriptionHeight, containerHeight;
  var Audio;
  var TypeAnim;
  
  /*
  Ajax functions
  */
  
  
  // Change browser url
  
  function ChangeBrowserURL(content, title, href) {
    var content_object = {};
    
    if(content !== null) {
      content_object = {'content': content};
    } else {
      content_object = null;
    }
  
    if (history.pushState) {
      history.pushState(content_object, title, href);
    } else {
      document.location.hash = href;
    }
  }
  
  
  // Get page with ajax
  // usage: AjaxPage (string: url)
  
  function AjaxPage(href) {
  
    $('body, html').stop().animate({scrollTop:0}, '0', 'swing');
  
    var docClass = '';
    $.get(href, function (data) {
      
      var parser = new DOMParser();
      var doc = parser.parseFromString(data, "text/html");
      docClass = doc.body.getAttribute('class');
      var docTitle = doc.title;
      parser = doc = null;
  
      document.title = docTitle;
      $('body').attr('class', docClass);
      
      var findData;
      var data_html;
  
      findData = $(data).find('.o-main');
      data_html = findData[0].innerHTML;
      $('.o-main').html(data_html); 
      
      new ChangeBrowserURL(data_html, docTitle, href);
  
    }).done(function( data ) {
     
    });
  }
  
  
  function OnAjaxComplete() {
    loader = $('.u-loader');
    var tl = new TimelineLite();
  
    tl.to(loader, 0.3, {opacity: 0})
      .to(loader, 0, {width:"0%", height: "0%"});
  
    animatingTransition = false;
    card = $('.m-pan__inner');
  
    if($('.u-reveal').length) {
      new Reveal();
    }
  
    fading = $('.js-fade');
    descriptionHeight = $('.a-description').outerHeight();
    containerHeight = $('.o-container').outerHeight();
    
    $('.o-main').css('padding-top', descriptionHeight+'px');
  
    if($('body').hasClass('home')) {
      new TypeAnim('.js-type--1');
    }
  }
  
  // History pop state
  
  window.onpopstate = function(event) {
    //console.log(event);
  
    if(event.state !== null) {
      $('main').html(event.state.content);
     // new AjaxPage(event.state.content);
      new OnAjaxComplete();
    } else {
      event.preventDefault();
      return false;
    }
  };
  
  
  // FUNCTIONS
  
  function DeleteItem($this) {
    $this.remove();
  }
  
  function HideItem($this) {
    $this.hide();
  }
  
  function LoadAjax(href) {
    var loader = $('.u-loader');
    var description = $('.a-description');
    var main = $('.o-main');
    var tl = new TimelineLite();
  
    //$('.o-main').css('padding-top', 0+'px');
  
    tl.to(loader, 0, {width:"100%", height: "100%"})
      .to(loader, 0.3, {opacity: 1, onComplete:AjaxPage, onCompleteParams:[href]});
  }
  
  function InfoWrapperHeight() {
    var infoHeight = $('.o-info__wrapper').outerHeight();
    $('.o-info').css('height', infoHeight);
  }
  
  function StickyText() {
    var projectText = $('.m-project-block__text');
  
    projectText.each(function() {
      var $this = $(this),
        offset = 200,
        parentContainer = $(this).parent(),
        containerTop = parentContainer.offset().top,  
        containerScrolledTop = containerTop - $(window).scrollTop(),
        containerBottom = containerTop + parentContainer.outerHeight() - $(this).height() - offset - $(window).scrollTop();
  
      if(containerBottom <= 0) {
        $this.css({
          'position': 'absolute',
          'top': 'auto',
          'bottom': 0
        });
      } else if(containerScrolledTop <= offset) {
        $this.css({
          'position': 'fixed',
          'top': 200,
          'bottom': 'auto',
        });
      } else {
        $this.css({
          'position': 'absolute',
          'top': 0,
          'bottom': 'auto',
        });
      }
  
    });
  }
  
  function StickInfo() {
    var contentBottom = $('.o-main').outerHeight(),
        infoHeight = $('.o-info').outerHeight();
  
    if($(window).scrollTop() >= ($(document).height() - (2*infoHeight))) {
      $('.o-info').css('visibility', 'visible');
    } else {
      $('.o-info').css('visibility', 'hidden');
    }
  
    if($(window).scrollTop() >= contentBottom) {
      $('.o-info__wrapper').addClass('o-info--stick');
    } else {
      $('.o-info__wrapper').removeClass('o-info--stick');
    }
    
    if($(window).scrollTop() >= (contentBottom - 600)) {
      $('.o-header').addClass('o-header--down-important');
    } else {
      $('.o-header').removeClass('o-header--down-important');
    }
    
  }
  
  function InfoScroll() {
    var contentBottom = $('.o-main').outerHeight();
    var body = $("html, body");
    
    body.stop().animate({scrollTop:contentBottom}, '300', 'swing');
  }
  
  
  var fadeStart = 100;
      fading = $('.js-fade');
  
  function FadeColor() {
    var contentBottom = $('.o-single-project').outerHeight();
    var offset = $(document).scrollTop(),
        opacity = 0;
  
    if (offset <= fadeStart) {
      opacity = 1;
    } else if (offset <= contentBottom) {
      opacity = 1 - offset/contentBottom;
    }
    fading.css('opacity',opacity);
  }
  
  // Lazyload config 
  
  
  
  // Reveal scroll 
  
  Reveal = function() {
    $( '.u-reveal:in-viewport' )
    .run(function(){
  
      if ( !$(this).hasClass('u-reveal--revealed') ) {
        var $this = $(this);
        var src = $(this).find('iframe').data('src');
  
        if(src) {
          $this.find('iframe').attr('src', src);
          $this.find('iframe').on('load', function(){
            $this.addClass('u-reveal--revealed');
            $this.children('.u-spinner').remove();
          });        
        } else {
          $this.addClass('u-reveal--revealed');
        }
  
      }
  
    });
  };
  
  function RevealLazy() {
    $('.js-reveal-lazy:in-viewport')
    .run(function(){
      if ( !$(this).hasClass('js-reveal-lazy--revealed') ) {
        var $this = $(this);
        $this.addClass('js-reveal-lazy--revealed');
        $this.find('.js-reveal-lazy__item').addClass('u-lazyload');
      }
    });
  }
  
  var muted = false;
  function Mute(audio) {
    
    if (!muted) {
      $(audio).animate({volume: 0}, 1000, function () {
        muted = true;
      });
    }
    else {
      $(audio).animate({volume: 1}, 1000, function () {
        muted = false;
      });
    }
  }
  
  $(document).on('click', '.js-audio', function(e) {
    var targetAudio = $(this).children('audio').get(0);
  
    if($(this).hasClass('js-audio--mute')) {
      $(this).removeClass('js-audio--mute');
      new Mute(targetAudio);
    } else {
      $(this).addClass('js-audio--mute');
      $('audio').each(function(){
        this.pause();
      });
      targetAudio.currentTime = 0;
      $(targetAudio).prop("volume", 1);
      targetAudio.play();
    }
  });
  
  
  
  $(document).on('click', 'a[href=".mute"]',function(e) {
    e.preventDefault();
    $('audio').each(function(){
      new Mute(this);
      //this.currentTime = 0;
    });
  });
  
  function ToggleAwards() {
    $('a.js-toggle-awards').click(function(e) {
      e.preventDefault();
      $('.u-hidden').toggleClass('u-hidden--visible');
  
      var awardsPosition;
  
      if (Foundation.MediaQuery.atLeast('medium')) {
        awardsPosition = $(this).offset().top;
      } else {
        awardsPosition = $('#js-extra-awards').offset().top;     
      }
  
      $("html, body").animate({ scrollTop: awardsPosition });
    });
  }
  
  // NAV
  
  $(document).on('click', '.m-top-bar__menu .a-home a',function(e) {
    e.preventDefault();
  
    $('.u-loader').css('background-color', '#FFFFFF');
    new LoadAjax($(this).attr('href'));
  });
  
  $(document).on('click', '.o-projects-nav__item',function(e) {
    e.preventDefault();
  
    new LoadAjax($(this).attr('href'));
  });
  
  $(document).on('click', 'a.m-project',function(e) {
    e.preventDefault();
  
    new LoadAjax($(this).attr('href'));
  });
  
  $(document).on('click', '#menu-item-46 a',function(e) {
    e.preventDefault();
    new InfoScroll();
  });
  
  $(document).on('mouseenter', 'a.m-project',function(e) {
    if(!animatingTransition) {
      var color = $(this).data('color');
      $('.o-content').css('background-color', color);
      $('.u-loader').css('background-color', color);
    }
  });
  
  $(document).on('mouseenter', 'a.o-projects-nav__item',function(e) {
    if(!animatingTransition) {
      var color = $(this).data('color');
      $('.o-projects-nav').css('background-color', color);
      $('.u-loader').css('background-color', color);
    }
  });
  
  var idleTime = 0;
  
  function timerIncrement() {
      idleTime = idleTime + 1;
      if (idleTime > 5000) {
        $('body').addClass("body--screensaver");
      } else {
        $('body').removeClass("body--screensaver");
      }
  }
  
  
  // Type anime
  var cursorAnimation = function() {
      $('#js-cursor').animate({
          opacity: 0
      }, 'fast', 'swing').animate({
          opacity: 1
      }, 'fast', 'swing');
  };
  
  var captionLength = 0;
  var caption = '';
  var selectedString = '';
  var targetsLenght;
  
  var NextCaption = function() {
    captionEl.next('#js-cursor').remove();
    var target = '.js-type--'+ (1+Math.floor(Math.random() * targetsLenght));
    if(target === captionEl) {
      target = '.js-type--'+ (1+Math.floor(Math.random() * targetsLenght));
    }
    if(target) {
      new TypeAnim(target);
    }
  };
  
  var Type = function() {
      captionEl.html(caption.substr(0, captionLength++));
      if(captionLength < caption.length+1) {
          setTimeout(Type, 50);
      } else {
        captionLength = 0;
        caption = '';
  
        setTimeout(NextCaption, 1000);
      }
  };
  
  function TypingEffect() {
      caption = selectedString;
      new Type();
  }
  
  var Erase = function() {
      captionEl.html(caption.substr(0, captionLength--));
      if(captionLength >= 0) {
          setTimeout(Erase, 50);
      } else {
        captionLength = 0;
        caption = '';
  
        new TypingEffect();
      } 
  };
  
  var ErasingEffect = function() {
      caption = captionEl.html();
      captionLength = caption.length;
      if (captionLength>0) {
        new Erase();
      }
  };
  
  TypeAnim = function(element) {
    var getStrings = element.replace('type', 'strings');
    var stringsLenght = $(getStrings).children().length;
  
    var randomString = Math.floor(1+Math.floor(Math.random() * stringsLenght));
        targetsLenght = $('.js-type').length;
        selectedString = $(getStrings+' p:nth-child('+randomString+')').text();
  
  
    setInterval (cursorAnimation, 600);
    captionEl = $(element);
    captionEl.after('<span id="js-cursor">|</span>');
    setTimeout(ErasingEffect, 2000);
  };
  
  
  // jQuery Events
  
  $(window).on('load', function() {
    if($('.u-reveal').length) {
      new Reveal();
    }
    if (Foundation.MediaQuery.atLeast('medium')) {
      new RevealLazy();
    }
  
    new InfoWrapperHeight();
    new ToggleAwards();
  
    if($('body').hasClass('home')) {
      new TypeAnim('.js-type--1');
    }
    
    descriptionHeight = $('.a-description').outerHeight(true);
    containerHeight = $('.o-main').outerHeight(true);
    console.log(descriptionHeight);

    $('.o-main').css('padding-top', descriptionHeight+'px');
  
  
    //Increment the idle time counter every minute.
    var idleInterval = setInterval(timerIncrement, 500); // 1 minute
  
    //Zero the idle timer on mouse movement.
    $(this).mousemove(function (e) {
      idleTime = 0;
    });
    $(this).keypress(function (e) {
      idleTime = 0;
    });
  });
  
  $(window).resize(function() {
    new InfoWrapperHeight();
    
    if (Foundation.MediaQuery.atLeast('medium')) {
      new RevealLazy();
    }
    
    descriptionHeight = $('.a-description').outerHeight(true);
    containerHeight = $('.o-main').outerHeight(true);
  
    $('.o-main').css('padding-top', descriptionHeight+'px');
  });
  
  var scrolled;
  
  $(document).scroll(function() {
    if($('.u-reveal').length) {
      new Reveal();
    }
    if (Foundation.MediaQuery.atLeast('medium')) {
      new RevealLazy();
    }
  
    new FadeColor();
    new StickInfo();
  
    scrolled = $(document).scrollTop();
  
    if($(document).scrollTop() >= descriptionHeight + 50) {
      $('.a-description').hide();
    } else {
      $('.a-description').show();
    }
  });
  
  document.onmousemove = function(event) {
    if(canMoveCard) {
      var halfWindowH = $(window).height()*0.5,
      halfWindowW = $(window).width()*0.5,
      maxRotationY = 30,
      maxRotationX = 30;
              
      var rotate_X;
      var rotate_Y;
      var invert = false;
      
      var rotateY = ((-event.pageX+halfWindowW)/halfWindowW)*maxRotationY,
          rotateX = ((event.pageY-$(window).scrollTop()-halfWindowH)/halfWindowH)*maxRotationX;
  
      if( rotateY > maxRotationY) {rotateY = maxRotationY; }
      if( rotateY < -maxRotationY ) { rotateY = -maxRotationY; }
      if( rotateX > maxRotationX) { rotateX = maxRotationX; }
      if( rotateX < -maxRotationX ) { rotateX = -maxRotationX; }
  
      card.attr("style", "transform: rotateY("+rotateY+"deg) rotateX("+rotateX+"deg);-webkit-transform: rotateY("+rotateY+"deg) rotateX("+rotateX+"deg);-moz-transform: rotateY("+rotateY+"deg) rotateX("+rotateX+"deg)");
    }
  };
  
  
  // Ajax events
  
  $(document).ajaxStart(function() {
    NProgress.start();
  });
  
  $(document).ajaxSuccess(function() {
    NProgress.done();
  });
  
  $(document).ajaxComplete(function() {
    new OnAjaxComplete();
  });
  
  
  })(jQuery);